import { transparentize } from 'polished';
import React, { useState, useMemo, useEffect } from 'react';
import { OverlayTrigger, Tooltip, Button, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';
import Colors from '../../utils/colors';

const TRWithTextarea = styled.tr`
  height: 100%;

  td {
    height: 100%;

    textarea {
      width: 100%;
      box-sizing: border-box;
      height: 163px;
      padding: 10px;

      &:focus {
        outline: none;
        box-shadow: 0px 0px 7px -3px ${transparentize(0.25, Colors.primary)} inset;
      }
    }
  }
`;

const FormPrefix = 'table_question_row_titles';
const TableQuestionRowTitles = observeStorage(
  ({ id, title, explanation, tooltip, columnTitles, rowTitles, newRowButton }) => {
    const storage = useStorage();
    const keyForStorage = `table_question_row_titles_${id}`;

    const { register, watch, getValues, setValue } = useForm({
      defaultValues: storage.dataWithChanges?.[keyForStorage] ?? {},
    });

    watch((data) => {
      storage.setItems({ [keyForStorage]: data });
    });

    useOnResetChapter(() => {
      // reset the form
      const fieldsInForm = Object.keys(getValues());
      for (const field of fieldsInForm) {
        setValue(field, '');
      }
      storage.setItems({ [keyForStorage]: {} });
    });

    const { rowHeader, columnTitlesArray } = useMemo(() => {
      if (columnTitles.includes('|')) {
        var columns = columnTitles.split('|');
        return {
          rowHeader: columns[0],
          columnTitlesArray: columns[1].split(';'),
        };
      } else {
        return { rowHeader: '', columnTitlesArray: columnTitles.split(';') };
      }
    }, [columnTitles]);

    var rowTitlesArray = rowTitles.split(';');
    const nbColumns = columnTitlesArray.length;

    const [nbExtraRows, setNbExtraRows] = useState(0);

    return (
      <section className="table-question-row-titles-repeatable content-section text-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 wysiwyg">
              <div className="question">
                <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                <div className="d-flex justify-content-between">
                  <div
                    className="text-wrapper wysiwyg"
                    dangerouslySetInnerHTML={{ __html: explanation }}
                  />
                  {tooltip !== null && (
                    <div className="tooltipQuestion">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip id={`tooltip-left`}>{tooltip}.</Tooltip>}
                      >
                        <Button className="tooltipButton" variant="">
                          ?
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>

                <Table bordered responsive hover>
                  <thead className="thead-dark">
                    <tr>
                      <th>{rowHeader}</th>
                      {columnTitlesArray.map((item, index) => {
                        return (
                          <th key={index} className="text-center columnTableQuestion">
                            {item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {rowTitlesArray.map((item, i) => {
                      return (
                        <TRWithTextarea key={i}>
                          <td>
                            <p>{item}</p>
                          </td>
                          {Array.from({ length: nbColumns }, (_, j) => (
                            <td key={j} className="text-center tdNoBorder">
                              <textarea
                                className="tableTextarea"
                                {...register(`${FormPrefix}_${id}_${i}_${j}`)}
                              ></textarea>
                            </td>
                          ))}
                        </TRWithTextarea>
                      );
                    })}

                    {Array.from({ length: nbExtraRows }, (_, i) => (
                      <TRWithTextarea key={i}>
                        <td className="text-center tdNoBorder">
                          <textarea
                            className="tableTextarea"
                            {...register(`${FormPrefix}_${id}_extra_${i}`)}
                          ></textarea>
                        </td>
                        {Array.from({ length: nbColumns }, (_, j) => (
                          <td key={j} className="text-center tdNoBorder">
                            <textarea
                              className="tableTextarea"
                              {...register(`${FormPrefix}_${id}_extra_${i}_${j}`)}
                            ></textarea>
                          </td>
                        ))}
                      </TRWithTextarea>
                    ))}
                  </tbody>
                </Table>
                {newRowButton.content === 'true' && (
                  <Button
                    className="tableButton"
                    variant="secondary"
                    onClick={() => setNbExtraRows((value) => value + 1)}
                  >
                    NIEUWE RIJ
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default TableQuestionRowTitles;
