import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

export const MatrixStrafbareFeiten = observeStorage(({ id, content, questions }) => {
  const storage = useStorage();
  const keyForStorage = `internet_en_waarden_${id}`;
  const dataFromStorage = storage.getItem(keyForStorage) ?? {};

  const [answers, setAnswers] = useState(dataFromStorage.answers ?? {});
  const [showResults, setShowResult] = useState(dataFromStorage.showResults ?? false);

  const resetHandler = () => {
    setAnswers({});
    setShowResult(false);
    storage.setItem(keyForStorage, {});
  };

  useOnResetChapter(resetHandler);

  const onChangeHandler = (question, index) => (e) => {
    const checked = !!e.target?.checked;
    setAnswers((currentAnswers) => {
      const newAnswers = {
        ...currentAnswers,
        [`${question.id}_${index}`]: {
          ...(currentAnswers[`${question.id}_${index}`] ?? {}),
          checked,
          answer: question.options[index],
        },
      };
      storage.setItem(keyForStorage, { answers: newAnswers, showResults });

      return newAnswers;
    });
  };

  const onShowResults = (value) => {
    setShowResult(value);
    storage.setItem(keyForStorage, { answers, showResults: value });
  };

  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <h2>Matrix van strafbare feiten</h2>
            {!showResults ? (
              <form id="form">
                {questions.map((question, index) => {
                  return (
                    <div key={index} className="question mb-3">
                      <h4>
                        Vraag {index + 1} - {question.question}
                      </h4>
                      <div className="closed-question-answers column">
                        {question.options.map((option, i) => (
                          <div className="answer" key={i}>
                            <input
                              type="checkbox"
                              id={`q_${question.id}_${i}`}
                              name={`q_${question.id}_${i}`}
                              value={option}
                              checked={!!answers[`${question.id}_${i}`]?.checked}
                              onChange={onChangeHandler(question, i)}
                            />
                            <label htmlFor={`q_${question.id}_${i}`}>{option}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
                <div className="text-center">
                  <Button
                    className="mt-3"
                    variant="secondary"
                    type="submit"
                    onClick={() => {
                      onShowResults(true);
                    }}
                  >
                    Bekijk resultaat
                  </Button>
                </div>
              </form>
            ) : (
              <div id="resultMatrixStrafbareFeiten">
                <p>Je hebt geconcludeerd dat de volgende stellingen op jou van toepassing zijn:</p>
                <ul>
                  {Object.values(answers)
                    .filter((o) => o.checked)
                    .map((result, index) => {
                      return <li key={index}>{result.answer}</li>;
                    })}
                </ul>
                <div className="wysiwyg mt-4 mb-3" dangerouslySetInnerHTML={{ __html: content }} />
                <div className="text-center">
                  <Button
                    className="mt-3"
                    variant="secondary"
                    type="submit"
                    onClick={() => {
                      onShowResults(false);
                    }}
                  >
                    Aanpassen
                  </Button>
                  <Link to="/zelfbewustzijn" className="btn btn-outline-secondary mt-3 ml-2">
                    Naar overzicht
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export default MatrixStrafbareFeiten;
