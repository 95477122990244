import React from 'react';

export default function TextHighlight({ highlight, content, accent }) {
  return (
    <section className={`content-section text-highlight-section accent-${accent}`}>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="text-wrapper col-md-5  col-12">
            <div className="text-wrapper wysiwyg" dangerouslySetInnerHTML={{ __html: highlight }} />
          </div>
          <div className="text-wrapper col-md-6 col-sm-12 col-12 textHighlighted">
            <div className="text-wrapper wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    </section>
  );
}
