import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { observeStorage, useStorage } from '../../templates/localStorageContext';

const StyledLi = styled.li`
  &.is-read {
    list-style: none;
    position: relative;
  }

  .checkmark {
    display: inline-block;

    svg {
      position: absolute;
      width: 16px;
      left: -22px;
      top: 3px;
    }
  }
`;

export const Chapter = observeStorage(({ id, order, title, link, short }) => {
  const storage = useStorage();
  const isRead = storage?.isLoggedIn && storage?.isRead(id);

  if (short) {
    return (
      <StyledLi className={classNames('chapter', { 'is-read': isRead })}>
        {isRead && (
          <span className="checkmark">
            <FontAwesomeIcon className="sideButtonIcon" icon={faCheck} />
          </span>
        )}
        {link ? <Link to={link}>{title}</Link> : title}
      </StyledLi>
    );
  } else {
    return (
      <div className="chapter">
        <h1>
          {order} - {title}
        </h1>
        <br></br>
      </div>
    );
  }
});

export default Chapter;
