import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ChapterHeader({ module, alternativeTitle }) {
  const alternativeTitleWithoutTags = alternativeTitle?.replace(/(<([^>]+)>)/gi, '');

  return (
    <div className="content-section heading-chapter d-print-none">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-4 col-lg-3 col-12 d-flex align-items-center justify-content-center">
            <div className="header-icon">
              {module.image && (
                <GatsbyImage
                  image={getImage(module.image.localFile)}
                  alt={module.title}
                  layout="constrained"
                  objectFit="contain"
                />
              )}
            </div>
          </div>
          <div className="col-md-8 col-lg-9">
            <h1>{module.title}</h1>
            {alternativeTitleWithoutTags ? (
              <div dangerouslySetInnerHTML={{ __html: alternativeTitle }}></div>
            ) : (
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: module.description }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
