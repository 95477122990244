import React, { useState } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Range, getTrackBackground } from 'react-range';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

export const RangeQuestion = observeStorage(
  ({ id, question, explanation, tooltip, leftExtreme = 1, rightExtreme = 10 }) => {
    const storage = useStorage();
    const keyForStorage = `range_question_${id}`;

    const [values, setValues] = useState(storage.getItem(keyForStorage)?.values ?? [leftExtreme]);

    const resetHandler = () => {
      setValues([leftExtreme]);
      storage.setItem(keyForStorage, {});
    };

    useOnResetChapter(resetHandler);

    const onChangeHandler = (values) => {
      setValues(values);
      storage.setItem(keyForStorage, { values });
    };

    return (
      <section className="content-section text-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 wysiwyg">
              <div className="question">
                <h4 dangerouslySetInnerHTML={{ __html: question }}></h4>
                <div className="d-flex justify-content-between">
                  <div
                    className="text-wrapper wysiwyg"
                    dangerouslySetInnerHTML={{ __html: explanation }}
                  ></div>
                  {tooltip !== null && (
                    <div className="tooltipQuestion">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip id={`tooltip-left`}>{tooltip}.</Tooltip>}
                      >
                        <Button className="tooltipButton" variant="">
                          ?
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
                <div className="rangeScale-wrapper">
                  <div className="rangeScale">
                    <ul className="rangeScale-values">
                      <li>{leftExtreme}</li>
                      <li>{rightExtreme}</li>
                    </ul>
                  </div>
                  <Range
                    values={values}
                    step={1}
                    min={leftExtreme}
                    max={rightExtreme}
                    onChange={onChangeHandler}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: '36px',
                          display: 'flex',
                          width: '100%',
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: '5px',
                            width: '100%',
                            borderRadius: '4px',
                            background: getTrackBackground({
                              values: values,
                              colors: ['#dd7373', '#cbd6e2'],
                              min: leftExtreme,
                              max: rightExtreme,
                            }),
                            alignSelf: 'center',
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '32px',
                          width: '32px',
                          borderRadius: '16px',
                          backgroundColor: '#FFF',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: '0px 2px 6px #AAA',
                        }}
                      >
                        <div
                          style={{
                            height: '16px',
                            width: '5px',
                            backgroundColor: isDragged ? '#dd7373' : '#cbd6e2',
                          }}
                        />
                      </div>
                    )}
                  />
                  <div className="rangeScale-result">{values[0]}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default RangeQuestion;
