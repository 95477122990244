import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLunr } from 'react-lunr';
import { ListGroup } from 'react-bootstrap';
import { useQueryParam, StringParam } from 'use-query-params';

const SearchResults = ({ searchQuery }) => {
  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `);

  const index = queryData.localSearchPages.index;
  const store = queryData.localSearchPages.store;

  const results = useLunr(searchQuery, index, store);

  function getModulePrefix(path) {
    const splittedPath = path.split('/');
    if (splittedPath[1] === 'modules') {
      const [moduleNb] = splittedPath[2].split('-');

      if (moduleNb) {
        return `Module ${moduleNb} - `;
      }
    }
  }

  return (
    <div className="content-sectiond-print-none">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-10 col-lg-10 mb-5 mt-5">
            <h2>Zoekresultaten</h2>
            {results.length > 0 ? (
              <ListGroup>
                {results.map((result) => (
                  <ListGroup.Item key={result.fullpath}>
                    <a href={result.fullpath}>
                      {getModulePrefix(result.fullpath)}
                      {result.title}
                    </a>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p>No results!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchResultsWrapper = () => {
  const [search] = useQueryParam('search_query', StringParam);

  if (search != null && search.length > 0) {
    if (search.includes('%20')) {
      search.replace(/%20/g, ' ');
    }

    return <SearchResults searchQuery={search} />;
  } else {
    return null;
  }
};

export default SearchResultsWrapper;
