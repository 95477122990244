import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';
import { MenuContext } from '../../templates/contexts';
import Chapters from './chapters';
import Documents from './documents';

const CardTitle = styled.h4`
  margin-top: 25px;
  font-size: 24px;
  line-height: 32px;
`;

export const Module = ({
  index,
  title,
  description,
  image,
  chapters,
  documents,
  notApplicable = false,
}) => {
  const customMenu = useContext(MenuContext);
  const [showChapter, setShowChapter] = useState(false);
  const professionals = documents != null && documents.length > 0;

  const clickGoal = () => setShowChapter(false);
  const clickChapter = () => setShowChapter(true);

  const handleKeyDown = (handler) => {
    return (ev) => {
      if (ev.keyCode === 13 && handler) {
        handler();
      }
    };
  };

  const link = chapters && chapters.length > 0 ? chapters[0].fullpath : undefined;

  return (
    <div
      className={classNames('module-card card d-flex flex-column', {
        'not-applicable': notApplicable,
        applicable: !notApplicable,
        [`card-${customMenu?.slug}`]: customMenu?.slug != null,
      })}
    >
      <div className="card-header text-center">
        {image != null && (
          <GatsbyImage
            className="card-icon"
            image={getImage(image.localFile)}
            alt={title ?? ''}
            layout="constrained"
            objectFit="contain"
          />
        )}
        <CardTitle>
          {index != null ? `${index + 1}. ` : ''}
          {title}
        </CardTitle>
      </div>
      <div className="d-flex card-tabs justify-content-center">
        <div
          tabIndex="0"
          role="tab"
          className={`card-tab active-${!showChapter}`}
          onClick={clickGoal}
          onKeyDown={handleKeyDown(clickGoal)}
        >
          doelen
        </div>
        <div
          tabIndex="0"
          role="tab"
          className={`card-tab active-${showChapter}`}
          onClick={clickChapter}
          onKeyDown={handleKeyDown(clickChapter)}
        >
          {professionals ? 'oefeningen' : 'onderdelen'}
        </div>
      </div>
      <div className="card-body">
        {showChapter ? (
          <ul className="card-chapter-list">
            {professionals
              ? documents && <Documents documents={documents} />
              : chapters && <Chapters chapters={chapters} />}
          </ul>
        ) : (
          <>
            <div className="goal" dangerouslySetInnerHTML={{ __html: description }} />
            {link && (
              <div className="start-module-wrapper">
                <Link className="start-module-link" to={link}>
                  Start de module <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const Modules = ({ modules }) => {
  const [applicableTo] = useQueryParam('voor', StringParam);
  const [applicableFor] = useQueryParam('over', StringParam);

  const modulesWithApplicableData = useMemo(() => {
    return sortBy(modules, 'order').map((module) => {
      const applicable =
        (applicableTo == null && applicableFor == null) ||
        (applicableTo && module.applicableTo.includes(applicableTo)) ||
        (applicableFor && module.applicableFor.includes(applicableFor));

      return {
        ...module,
        applicable,
        notApplicable: !applicable,
      };
    });
  }, [modules, applicableTo, applicableFor]);

  useEffect(() => {
    if (applicableTo || applicableFor) {
      // scroll to first applicable item
      const applicableItem = modulesWithApplicableData.find((i) => i.applicable);

      if (applicableItem) {
        scrollTo(`#module_${applicableItem.nimbuId}`, 'center');
      }
    }
  }, [modulesWithApplicableData, applicableTo, applicableFor]);

  return (
    <div className="content-section module-overview-section">
      <div className="container">
        <div className="row">
          {modulesWithApplicableData.map((module, index) => (
            <div id={`module_${module.nimbuId}`} key={index} className="col-md-6 card-wrapper">
              <Module index={index} {...module} card />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modules;
