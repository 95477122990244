import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

export const InternetAfhankelijkheid = observeStorage(({ id, questions, content }) => {
  const storage = useStorage();
  const keyForStorage = `internet_afhankelijkheid_${id}`;
  const dataFromStorage = storage.getItem(keyForStorage) ?? {};

  const [answers, setAnswers] = useState(dataFromStorage.answers ?? {});
  const [showResults, setShowResult] = useState(dataFromStorage.showResults ?? false);

  const resetHandler = () => {
    setAnswers({});
    setShowResult(false);
    storage.setItem(keyForStorage, {});
  };

  useOnResetChapter(resetHandler);

  const onChangeHandler = (question, option) => () => {
    setAnswers((currentAnswers) => {
      const newAnswers = {
        ...currentAnswers,
        [question.id]: option,
      };
      storage.setItem(keyForStorage, { answers: newAnswers, showResults });
      return newAnswers;
    });
  };

  const onShowResults = (value) => {
    setShowResult(value);
    storage.setItem(keyForStorage, { answers, showResults: value });
  };

  const yesCounter = useMemo(() => {
    return Object.values(answers).filter((answer) => answer === true).length;
  }, [answers]);

  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <h2>Internetafhankelijkheid</h2>
            {!showResults ? (
              <form id="form">
                {questions.map((question, index) => (
                  <div className="question" key={index}>
                    <h4>Vraag {index + 1}</h4>
                    <div className="justify-content-center question">
                      <label>{question.content}</label>
                      <div className="closed-question-answers">
                        <input
                          type="radio"
                          id={`q_${index}_yes`}
                          name={`q_${index}`}
                          value="Ja"
                          checked={answers[question.id] === true}
                          onChange={onChangeHandler(question, true)}
                          required
                        />
                        <label htmlFor={`q_${index}_yes`}>Ja</label>
                        <input
                          type="radio"
                          id={`q_${index}_no`}
                          name={`q_${index}`}
                          value="Nee"
                          checked={answers[question.id] === false}
                          onChange={onChangeHandler(question, false)}
                          required
                        />
                        <label htmlFor={`q_${index}_no`}>Nee</label>
                      </div>
                    </div>
                  </div>
                ))}
                <Button
                  className=""
                  variant="secondary"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    onShowResults(true);
                  }}
                >
                  Bekijk resultaat
                </Button>
              </form>
            ) : (
              <div id="results">
                <b>Je hebt {yesCounter} vragen met ‘Ja’ beantwoord.</b>
                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />

                <Button className="" variant="secondary" type="button" onClick={resetHandler}>
                  Opnieuw
                </Button>
                <Link to="/zelfbewustzijn" className="btn btn-outline-secondary ml-2">
                  Naar overzicht
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export default InternetAfhankelijkheid;
