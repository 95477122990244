import React from 'react';
import { graphql } from 'gatsby';

export default function Document({ name, url }) {
  return (
    <li className="chapter">
      <a href={url}>{name}</a>
    </li>
  );
}

// export const query = graphql`
//   fragment Document on NimbuDocumentsChannelEntry {
//     id
//     name
//     module {
//       title
//       slug
//     }
//   }
// `;
