import React, { useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-modal';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import bgPattern from '../../images/header-bg-pattern.png';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Select from 'react-select';
import { navigate } from '@reach/router';
import { useTranslations } from '../../templates/contexts';

export default function HeaderHome({ title, backgroundOverlay, backgroundOverlayOpacity }) {
  const translations = useTranslations();
  const query = useStaticQuery(graphql`
    query SelectItemsQuery {
      applicableTo: allNimbuApplicableToChannelEntry {
        nodes {
          id
          name
          slug
        }
      }
      applicableFor: allNimbuApplicableForChannelEntry {
        nodes {
          id
          name
          slug
        }
      }
    }
  `);

  const selectItems = useMemo(() => {
    const applicableToItems = query.applicableTo.nodes;
    const applicableForItems = query.applicableFor.nodes;

    return {
      applicableTo: applicableToItems,
      applicableFor: applicableForItems,
    };
  }, [query]);

  const [cookies, setCookie] = useCookies(['cookie-name']);
  const [modalIsOpen, setIsOpen] = React.useState(!cookies.timeLoggedin);

  useEffect(() => {
    if (!modalIsOpen && !cookies.timeLoggedin) {
      const today = Date.now();
      setCookie('timeLoggedin', today, { path: '/' });
    }
  }, [modalIsOpen, setCookie, cookies.timeLoggedin]);

  function closeModal() {
    if (window._paq) {
      window._paq.push(['setConsentGiven']);
    }
    setIsOpen(false);
  }
  const overlayClass = `overlay-${backgroundOverlay}`;
  const opacityClass = `overlay-opacity-${backgroundOverlayOpacity}`;
  const style = { backgroundImage: `url('${bgPattern}')` };
  const overlayNode = backgroundOverlay !== 'None' ? <div className="overlay"></div> : '';
  const mytitle = title ? <h1 className="big-title">{title}</h1> : '';

  return (
    <section
      className={`heading-home-section with-image ${overlayClass} ${opacityClass}`}
      style={style}
    >
      {overlayNode}
      <div id="gatsby-focus-wrapper"></div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 col-md-10 col-12 d-flex justify-content-center align-items-center">
            <div className="page-title-wrapper">{mytitle}</div>
          </div>
          <div className="col-lg-4 col-md-9 col-12 waarTeStarten">
            <div className="onboarding-wrapper">
              <h4>Waar te starten?</h4>
              <p>
                Je kan gewoon wat rondkijken of meteen starten met de zelfhulpmodule. We kunnen je
                ook even op weg helpen…
              </p>
              <form>
                <Select
                  className="mb-3"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="applicable-to"
                  placeholder="Ik ben / ik heb ..."
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.slug}
                  options={selectItems.applicableTo}
                  onChange={(o) => navigate(`/modules?voor=${o.slug}`)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#6f8196',
                    },
                  })}
                />
                <Select
                  className="mb-3"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="applicable-for"
                  placeholder="Ik ben op zoek naar / ik wil ..."
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.slug}
                  options={selectItems.applicableFor}
                  onChange={(o) => navigate(`/modules?over=${o.slug}`)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#6f8196',
                    },
                  })}
                />
                <div className="btns d-flex justify-content-center flex-wrap">
                  <Link className="form-alt-action" to="/modules">
                    Naar zelfhulpmodule
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Modal privacy"
        ariaHideApp={false}
      >
        <div
          className="mb-3"
          dangerouslySetInnerHTML={{ __html: translations['cookie.text_html'] }}
        ></div>
        <div className="text-center">
          {translations['cookie.button.left'] !== '' && translations['cookie.button.left'] != null && (
            <Button
              className="mr-3 mb-3"
              variant="tertiary"
              onClick={() => navigate('https://www.google.com')}
            >
              {translations['cookie.button.left']}
            </Button>
          )}
          {translations['cookie.button.right'] !== '' &&
            translations['cookie.button.right'] != null && (
              <Button className="mr-3 mb-3" variant="secondary" onClick={closeModal}>
                {translations['cookie.button.right']} »
              </Button>
            )}
        </div>
      </Modal>
    </section>
  );
}
