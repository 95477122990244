import React from 'react';
import { Button, Accordion, Card } from 'react-bootstrap';

export default function AccordionContent({ title, content }) {
  var titlesArray = title.split(';');
  var contentArray = content.split(';');
  const Cards = titlesArray.map((item, index) => {
    return (
      <Card key={index}>
        <Card.Header className="cardAccordion">
          <Accordion.Toggle
            as={Button}
            variant="link"
            dangerouslySetInnerHTML={{ __html: item }}
            eventKey={item}
          ></Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={item}>
          <Card.Body dangerouslySetInnerHTML={{ __html: contentArray[index] }}></Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });

  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <Accordion>{Cards}</Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}
