import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Image({ image, alt }) {
  return (
    <section className="content-section image-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="text-wrapper col-12">
            <GatsbyImage image={image} alt={alt} layout="constrained" objectFit="contain" />
          </div>
        </div>
      </div>
    </section>
  );
}
