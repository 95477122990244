import React from 'react';

export default function HighlightedText({ content }) {
  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <div className="highlightedtext">
              <div className="text-wrapper wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
