import React, { useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip, Button, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';
import { useForm } from 'react-hook-form';
import { transparentize } from 'polished';
import Colors from '../../utils/colors';

const TRWithTextarea = styled.tr`
  height: 100%;

  td {
    height: 100%;

    textarea {
      width: 100%;
      box-sizing: border-box;
      height: 163px;
      padding: 10px;

      &:focus {
        outline: none;
        box-shadow: 0px 0px 7px -3px ${transparentize(0.25, Colors.primary)} inset;
      }
    }
  }
`;

const StyledSection = styled.section`
  .table {
    thead {
      th {
        vertical-align: middle;
      }
    }
  }
`;
const FormPrefix = 'table_question';
const TableQuestion = observeStorage(({ id, title, explanation, tooltip, headers, examples }) => {
  const storage = useStorage();
  const keyForStorage = `table_question_${id}`;

  const { register, watch, getValues, setValue } = useForm({
    defaultValues: storage.dataWithChanges?.[keyForStorage] ?? {},
  });

  watch((data) => {
    storage.setItems({ [keyForStorage]: data });
  });

  const [nbExtraRows, setNbExtraRows] = useState(storage.data[`nbExtraRows_${id}`] ?? 0);
  const addRow = () => {
    setNbExtraRows(nbExtraRows + 1);
    storage.setItem(`nbExtraRows_${id}`, nbExtraRows + 1);
  };

  useOnResetChapter(() => {
    // reset the form
    const fieldsInForm = Object.keys(getValues());
    for (const field of fieldsInForm) {
      setValue(field, '');
    }
    storage.setItems({ [keyForStorage]: {} });

    // reset the extra rows
    setNbExtraRows(0);
    storage.setItem(`nbExtraRows_${id}`, 0);
  });

  const { titles, subtitles, examplesGiven } = useMemo(() => {
    const titles = [];
    const subtitlesList = [];

    headers.split('|').forEach((item) => {
      const [title, concatenatedSubtitles] = item.split('*');
      const subtitles = (concatenatedSubtitles?.split(';') ?? []).filter(
        (subtitle) => !isEmpty(subtitle)
      );

      subtitlesList.push(...subtitles);
      titles.push({
        title,
        nbSubtitles: subtitles.length,
      });
    });

    return {
      examplesGiven: examples?.split(';').filter((example) => !isEmpty(example)) ?? [],
      titles,
      subtitles: subtitlesList.filter((subtitle) => !isEmpty(subtitle)),
    };
  }, [headers, examples]);

  return (
    <StyledSection className="table-question-repeatable content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <div className="question">
              <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
              <div className="d-flex justify-content-between">
                <div
                  className="text-wrapper wysiwyg"
                  dangerouslySetInnerHTML={{ __html: explanation }}
                />
                {tooltip !== null && (
                  <div className="tooltipQuestion">
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip id={`tooltip-left`}>{tooltip}.</Tooltip>}
                    >
                      <Button className="tooltipButton" variant="">
                        ?
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </div>

              <Table bordered responsive hover>
                <thead className="thead-dark">
                  <tr>
                    {titles.map(({ title, nbSubtitles }, index) => (
                      <th
                        key={index}
                        className="text-center"
                        style={{ width: `${100 / titles.length}%` }}
                        {...(nbSubtitles > 1 ? { colSpan: nbSubtitles } : {})}
                      >
                        {title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <TRWithTextarea className="table-secondary">
                    {subtitles.map((subtitle, index) => (
                      <td key={index} className="text-center align-middle">
                        {subtitle}
                      </td>
                    ))}
                  </TRWithTextarea>
                  <TRWithTextarea className="table-info">
                    {examplesGiven.map((example, index) => (
                      <td key={index} className="text-center align-middle">
                        {example}
                      </td>
                    ))}
                  </TRWithTextarea>
                  {Array.from({ length: 3 + nbExtraRows }, (_, index) => (
                    <TRWithTextarea key={index}>
                      {titles.map(({ nbSubtitles }, titleIndex) =>
                        Array.from({ length: Math.max(nbSubtitles ?? 1, 1) }, (_, innerIndex) => (
                          <td
                            key={`${titleIndex}_${innerIndex}`}
                            className="text-center tdNoBorder"
                          >
                            <textarea
                              className="tableTextarea"
                              {...register(
                                `${FormPrefix}_${id}_${index}_${titleIndex}_${innerIndex}`
                              )}
                            ></textarea>
                          </td>
                        ))
                      )}
                    </TRWithTextarea>
                  ))}
                </tbody>
              </Table>
              <Button className="tableButton" variant="secondary" onClick={addRow}>
                NIEUWE RIJ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StyledSection>
  );
});

export default TableQuestion;
