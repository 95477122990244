import { faBell, faPrint, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faRightFromBracket, faShredder, faUserVneck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapForm from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import styled from 'styled-components';
import useBus from 'use-bus';
import { useTranslations } from '../templates/contexts';
import {
  observeStorage,
  useOnStorageChanges,
  useOnStorageLoad,
  useOnStorageSave,
  useStorage,
} from '../templates/localStorageContext';

import { globalHistory } from '@reach/router';

const loginModalStyle = {
  content: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    maxWidth: '600px',
  },
};

const Hint = styled.div`
  font-size: 16px;
  line-height: 1.5;

  * {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const Form = styled(BootstrapForm)`
  margin-top: 3rem;
  padding: 0 2rem;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;

  input[type='password'] {
    font-size: 20px;
  }

  .btn-link {
    -webkit-appearance: none;
    padding: 0;
    font-size: 16px;
    margin-left: 0.5rem;
    display: inline-block;
  }

  .alert-warning {
    .btn-link {
      color: #856404;
    }
  }
`;

function prepareToPrint() {
  // console.log("prepare to print")
  var rangesliders = document.getElementsByClassName('rangeslider');
  // get rid of the rangeslider styling
  var i;
  for (i = 0; i < rangesliders.length; i++) {
    rangesliders[i].style.webkitAppearance = 'auto';
  }
  // show all the normal radiobuttons
  var radiobuttons = document.getElementsByClassName('radioInputWithAnimation');
  for (i = 0; i < radiobuttons.length; i++) {
    radiobuttons[i].style.display = 'block';
  }
  // hide the styled radiobuttons
  var radiobuttonsWithAnimation = document.getElementsByClassName('circle');
  for (i = 0; i < radiobuttonsWithAnimation.length; i++) {
    radiobuttonsWithAnimation[i].style.display = 'none';
  }
  // hide headers and footers - elements with className noPrintInChapter
  var radiobuttonsLikertScale = document.getElementsByClassName('noPrintInChapter');
  for (i = 0; i < radiobuttonsLikertScale.length; i++) {
    radiobuttonsLikertScale[i].className += ' d-print-none';
  }
  window.print();
}

const TooltipTrigger = ({ hint, children, ...rest }) => (
  <OverlayTrigger
    placement="left"
    delay={{ show: 0, hide: 100 }}
    {...rest}
    overlay={(props) => (
      <Tooltip id="button-tooltip" {...props}>
        {hint}
      </Tooltip>
    )}
  >
    {children}
  </OverlayTrigger>
);

const LoginModal = ({ show, close }) => {
  const storage = useStorage();
  const translations = useTranslations();

  const [loginFailed, setLoginFailed] = useState(false);
  const [animateShake, setAnimateShake] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    formState: { errors, isValid },
  } = useForm({
    shouldUnregister: false,
  });

  const onSubmit = async (data) => {
    if (!data.password || data.password.trim() === '') {
      return;
    }

    if (!isRegistering && !storage.isRegistered) {
      setIsRegistering(true);
    } else {
      const loggedIn = await storage.login(data.password);
      if (loggedIn) {
        toast.success(translations['login.success']);
        close();
      } else {
        toast(translations['login.failed'], {
          icon: '🚫',
        });
        setAnimateShake(true);
        setLoginFailed(true);
        setTimeout(() => {
          setAnimateShake(false);
        }, 500);
      }
    }
  };

  const onReset = () => {
    const confirmed = window.confirm(translations['login.reset_confirmation']);
    if (confirmed === true) {
      storage.clear();
      setLoginFailed(false);
      setIsRegistering(true);

      toast.success(translations['login.reset_success']);
    }
  };

  const onClose = () => {
    setLoginFailed(false);
    setIsRegistering(false);
    close();
    resetForm();
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      style={loginModalStyle}
      contentLabel="Login"
      ariaHideApp={false}
      className={classNames({ 'animate__animated animate__headShake': animateShake })}
    >
      <div className="text-center">
        <h3>{translations['login.title']}</h3>

        <Hint
          className="mb-3 wysiwyg"
          dangerouslySetInnerHTML={{ __html: translations['login.description_html'] }}
        ></Hint>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="password">
          <Form.Control
            type="password"
            placeholder="Je wachtwoord..."
            isInvalid={!!errors?.password}
            {...register('password', {
              required: true,
            })}
          />
          {errors?.password && (
            <Form.Control.Feedback type="invalid">{errors?.password.message}</Form.Control.Feedback>
          )}
          <Form.Text className="text-muted">{translations['login.password_hint']}</Form.Text>
        </Form.Group>

        {isRegistering && (
          <Form.Group className="mb-3" controlId="passwordRepeat">
            <Form.Control
              type="password"
              placeholder="Bevestig je wachtwoord..."
              isInvalid={!!errors?.passwordRepeat}
              {...register('passwordRepeat', {
                required: true,
                validate: (val) => {
                  if (watch('password') !== val) {
                    return 'Je bevestiging komt niet overeen met je wachtwoord hierboven...';
                  }
                },
              })}
            />

            {errors?.passwordRepeat && (
              <Form.Control.Feedback type="invalid">
                {errors?.passwordRepeat.message}
              </Form.Control.Feedback>
            )}
            <Form.Text className="text-muted">
              {translations['login.password_confirmation_hint']}
            </Form.Text>
          </Form.Group>
        )}

        {storage.isRegistered && loginFailed && (
          <Alert variant="warning" className="mt-3">
            {translations['login.already_registered']}
            <Button variant="link" onClick={onReset}>
              {translations['login.already_registered_click_here']}
            </Button>
          </Alert>
        )}

        <div className="text-center mt-3">
          <Button className="mr-3 mb-3" variant="secondary" size="lg" onClick={onClose}>
            {translations['login.buttons.cancel']}
          </Button>
          <Button
            disabled={!isValid}
            className="mr-3 mb-3"
            variant="tertiary"
            size="lg"
            type="submit"
          >
            {translations['login.buttons.login']}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const SideButtons = observeStorage(() => {
  const translations = useTranslations();

  const [canSaveChanges, setCanSaveChanges] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  useBus('showLogin', () => setShowLogin(true), [setShowLogin]);
  useBus('hideLogin', () => setShowLogin(false), [setShowLogin]);

  const storage = useStorage();

  const beforeUnloadHandler = storage.isLoggedIn
    ? () => 'Opgelet: wanneer je de pagina vernieuwd of deze tab sluit wordt je uitgelogd!'
    : undefined;

  useBeforeunload(beforeUnloadHandler);

  useOnStorageChanges((storage) => {
    setCanSaveChanges(storage.isDirty);
  });

  useOnStorageLoad((storage) => {
    setCanSaveChanges(storage.isDirty);
  });

  useOnStorageSave((storage) => {
    setCanSaveChanges(storage.isDirty);
  });

  const resetCurrentChapter = () => {
    storage?.reset();
  };

  const destroyEverything = () => {
    const confirmed = window.confirm(translations['login.reset_confirmation']);
    if (confirmed === true) {
      storage?.clear();

      toast(translations['login.reset_success'], {
        icon: '👋',
      });
    }
  };

  const saveChanges = () => {
    storage.save();

    toast.success(translations['login.save_success']);
  };

  const showModal = () => {
    setShowLogin(true);
  };

  const closeModal = () => {
    setShowLogin(false);
  };

  const onLogout = () => {
    storage.logout();
    toast(translations['login.logged_out'], {
      icon: '👋',
    });
  };

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') toast.remove();
    });
  }, []);

  useBus('logout', () => onLogout());

  return (
    <section className="sideButtonsContainer position-sticky d-print-none">
      <TooltipTrigger hint={translations['tooltips.print']}>
        <button className="sideButton" onClick={prepareToPrint}>
          <FontAwesomeIcon className="sideButtonIcon" icon={faPrint} />
        </button>
      </TooltipTrigger>

      {storage.canReset && (
        <TooltipTrigger hint={translations['tooltips.erase']}>
          <button className="sideButton" onClick={resetCurrentChapter}>
            <FontAwesomeIcon className="sideButtonIcon" icon={faTrash} />
          </button>
        </TooltipTrigger>
      )}

      {canSaveChanges && (
        <TooltipTrigger hint={translations['tooltips.save']}>
          <button className="sideButton" onClick={storage.isLoggedIn ? saveChanges : showModal}>
            <FontAwesomeIcon className="sideButtonIcon" icon={faSave} />
          </button>
        </TooltipTrigger>
      )}

      {storage.isLoggedIn && (
        <TooltipTrigger hint={translations['tooltips.destroy']}>
          <button className="sideButton" onClick={destroyEverything}>
            <FontAwesomeIcon className="sideButtonIcon" icon={faShredder} />
          </button>
        </TooltipTrigger>
      )}

      {storage.isLoggedIn ? (
        <TooltipTrigger hint={translations['tooltips.logout']}>
          <button className="sideButton" onClick={onLogout}>
            <FontAwesomeIcon className="sideButtonIcon" icon={faRightFromBracket} />
          </button>
        </TooltipTrigger>
      ) : !canSaveChanges ? (
        <TooltipTrigger hint={translations['tooltips.login']}>
          <button className="sideButton" onClick={showModal}>
            <FontAwesomeIcon className="sideButtonIcon" icon={faUserVneck} />
          </button>
        </TooltipTrigger>
      ) : null}
      <TooltipTrigger hint={translations['tooltips.hide']}>
        <button className="sideButton">
          <a href="https://www.google.com/" rel="noreferrer noopener">
            <FontAwesomeIcon className="sideButtonIcon" icon={faBell} />
          </a>
        </button>
      </TooltipTrigger>

      <LoginModal show={showLogin} close={closeModal} />
    </section>
  );
});

export default SideButtons;
