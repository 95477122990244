import React from 'react';
import Document from './document';

export default function Documents({ documents }) {
  return (
    <>
      {documents.map((document, index) => (
        <Document key={index} url={document.url} name={document.name} />
      ))}
    </>
  );
}
