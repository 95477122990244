import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function TextImage({
  content,
  image,
  alt,
  accent,
  theme,
  alignment,
  imageSize,
  spacing,
}) {
  return (
    <section className={`content-section text-highlight-section theme-${theme} accent-${accent}`}>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div
            className="text-wrapper wysiwyg col-sm-6 col-lg-8"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="image-wrapper col-sm-6 col-lg-4">
            <div className="inner-image-wrapper">
              <GatsbyImage image={image} alt={alt ?? ''} layout="constrained" objectFit="contain" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
