import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

const Question = ({ question, themeId, topic, onChange, answers }) => {
  return (
    <div className="subquestion justify-content-center">
      <b className="title">{question}</b>
      <div className="likert-scale">
        <b>Zeer oneens</b>
        <span className="separator" />
        <b>Zeer eens</b>
      </div>
      <div className="likert-scale-items">
        {[...Array(9)].map((_, i) => (
          <div key={`scaleA-${themeId}-${i}`}>
            <input
              type="radio"
              name={`${topic}_${themeId}`}
              id={`${topic}_${themeId}_${i}`}
              value={i + 1}
              checked={answers[themeId]?.[topic] === i + 1}
              onChange={() => onChange(themeId, topic, i + 1)}
            />
            <label htmlFor={`${topic}_${themeId}_${i}`}>{i + 1}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const InternetEnWaarden = observeStorage(({ id, content, themes }) => {
  const storage = useStorage();
  const keyForStorage = `internet_en_waarden_${id}`;
  const dataFromStorage = storage.getItem(keyForStorage) ?? {};

  const [answers, setAnswers] = useState(dataFromStorage.answers ?? {});
  const [showResults, setShowResult] = useState(dataFromStorage.showResults ?? false);

  const resetHandler = () => {
    setAnswers({});
    setShowResult(false);
    storage.setItem(keyForStorage, {});
  };

  useOnResetChapter(resetHandler);

  const onChangeHandler = (themeId, key, value) => {
    setAnswers((currentAnswers) => {
      const newAnswers = {
        ...currentAnswers,
        [themeId]: {
          ...(currentAnswers[themeId] ?? {}),
          [key]: value,
        },
      };
      storage.setItem(keyForStorage, { answers: newAnswers, showResults });
      return newAnswers;
    });
  };

  const onShowResults = (value) => {
    setShowResult(value);
    storage.setItem(keyForStorage, { answers, showResults: value });
  };

  const scores = useMemo(() => {
    let scores = [];
    themes.forEach((theme) => {
      let score = {
        title: theme.theme,
        selected: answers[theme.id]?.selected ?? false,
        Invloed: answers[theme.id]?.Invloed ?? 0,
        Inspanning: answers[theme.id]?.Inspanning ?? 0,
      };
      scores.push(score);
    });
    const allAnwsers = Object.values(answers);
    let avarageScoreInvloed =
      allAnwsers.map((a) => a.Invloed || 0).reduce((a, b) => a + b, 0) / allAnwsers.length;

    let avarageScoreInspanning =
      allAnwsers.map((a) => a.Inspanning || 0).reduce((a, b) => a + b, 0) / allAnwsers.length;

    scores.push({
      theme: 'Gemiddelde',
      title: 'Gemiddelde',
      Invloed: avarageScoreInvloed.toFixed(2),
      Inspanning: avarageScoreInspanning.toFixed(2),
    });

    return scores;
  }, [themes, answers]);

  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <h2>Internetafhankelijkheid</h2>
            {!showResults ? (
              <form id="form">
                <div className="question">
                  <h4>Wat waardeer je in het leven?</h4>
                  <p>
                    Markeer de dingen die je belangrijk vindt in je leven in de lijst van
                    levensdomeinen hieronder:
                  </p>
                  <div>
                    {themes.map((theme, index) => {
                      return (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={`theme_${index}`}
                            name={`theme_${index}`}
                            value={theme.title}
                            checked={answers[theme.id]?.selected === true}
                            onChange={(e) =>
                              onChangeHandler(theme.id, 'selected', !!e.target.checked)
                            }
                          />
                          <label htmlFor={`theme_${index}`}>{theme.title}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {themes.map((theme, index) => {
                  return (
                    <div className="question" key={index}>
                      <h4>
                        Vraag {index + 1} - {theme.title}
                      </h4>
                      <Question
                        question={theme.inspanning}
                        themeId={theme.id}
                        answers={answers}
                        topic="Inspanning"
                        onChange={onChangeHandler}
                      />
                      <Question
                        question={theme.invloed}
                        themeId={theme.id}
                        answers={answers}
                        topic="Invloed"
                        onChange={onChangeHandler}
                      />
                    </div>
                  );
                })}
                <Button
                  className=""
                  variant="secondary"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    onShowResults(true);
                  }}
                >
                  Bekijk resultaat
                </Button>
              </form>
            ) : (
              <div id="resultInternetEnWaarden">
                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />

                <b>Resultaat:</b>
                <div id="chartInternetEnWaarden">
                  <ResponsiveContainer width={'99%'} height={500}>
                    <BarChart
                      data={scores}
                      layout="vertical"
                      margin={{
                        top: 5,
                        right: 0,
                        left: 80,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="4" />
                      <XAxis type="number" domain={[0, 10]} />
                      <YAxis type="category" dataKey="title" />
                      <Tooltip />
                      <Bar stackId="a" dataKey="Invloed">
                        {scores.map((entry, index) => (
                          <Cell
                            key={index}
                            fill={entry.selected === true ? '#66ad6a' : '#8dd98d'}
                          />
                        ))}
                      </Bar>
                      <Bar stackId="b" dataKey="Inspanning">
                        {scores.map((entry, index) => (
                          <Cell
                            key={index}
                            fill={entry.selected === true ? '#5c7c9e' : '#7ca8d6'}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <Button className="" variant="secondary" type="submit" onClick={resetHandler}>
                  Opnieuw
                </Button>
                <Link to="/zelfbewustzijn" className="btn btn-outline-secondary ml-2">
                  Naar overzicht
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export default InternetEnWaarden;
