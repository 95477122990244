import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { useTranslations } from '../templates/contexts';
import styled from 'styled-components';
import { useIsSSR } from '../utils/ssr';

const WYSIWYG = styled.div`
  *,
  &.wysiwyg * {
    color: white;
  }
`;

const ChapterPrivacyModal = () => {
  const translations = useTranslations();

  const isStaticHtml = useIsSSR();
  const [cookies, setCookie] = useCookies(['cookie-name']);
  const [modalIsOpen, setIsOpen] = React.useState(!isStaticHtml && !cookies.timeLoggedinChapter);

  const closeModal = () => {
    setIsOpen(false);
    setCookie('timeLoggedinChapter', Date.now(), { path: '/' });
  };

  return modalIsOpen ? (
    <div className="d-print-none privacy-modal-wrapper" id="privacyModal">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <div className="chapterPrivacyModal position-relative">
              <button className="sideButton position-absolute closeButton" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <WYSIWYG
                className="text-wrapper wysiwyg"
                dangerouslySetInnerHTML={{
                  __html: translations['privacy.exercises_notice_html'],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ChapterPrivacyModal;
