import React, { useMemo, useState } from 'react';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

export const OptionQuestion = observeStorage(({ id, question, explanation, options }) => {
  const storage = useStorage();
  const keyForStorage = `option_question_${id}`;
  const optionsArray = useMemo(() => options.split(';'), [options]);

  const [answers, setAnswers] = useState(storage.getItem(keyForStorage)?.answers ?? {});

  const resetHandler = () => {
    setAnswers({});
    storage.setItem(keyForStorage, {});
  };

  useOnResetChapter(resetHandler);

  const onChangeHandler = (index) => (e) => {
    const checked = !!e.target?.checked;

    setAnswers((currentAnswers) => {
      const newAnswers = {
        ...currentAnswers,
        [`${id}_${index}`]: checked,
      };
      storage.setItem(keyForStorage, { answers: newAnswers });
      return newAnswers;
    });
  };

  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <div className="question">
              <h4 dangerouslySetInnerHTML={{ __html: question }}></h4>
              <div
                className="checkboxcolumn-label text-wrapper wysiwyg"
                dangerouslySetInnerHTML={{ __html: explanation }}
              />
              <form>
                <div className="checkboxcolumn">
                  {optionsArray.map((option, index) => (
                    <div key={index} className="checkboxcolumn-option">
                      <input
                        type="checkbox"
                        id={`o_${id}_${index}`}
                        name={question}
                        value={option}
                        checked={!!answers[`${id}_${index}`]}
                        onChange={onChangeHandler(index)}
                      />
                      <label key={index} htmlFor={`o_${id}_${index}`}>
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default OptionQuestion;
