import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

const OpenQuestion = observeStorage(({ id, question, explanation, tooltip }) => {
  const storage = useStorage();
  const keyForStorage = `open_question_${id}`;

  const { register, watch, getValues, setValue } = useForm({
    defaultValues: storage.dataWithChanges?.[keyForStorage] ?? {},
  });

  watch((data) => {
    storage.setItems({ [keyForStorage]: data });
  });

  useOnResetChapter(() => {
    // reset the form
    const fieldsInForm = Object.keys(getValues());
    for (const field of fieldsInForm) {
      setValue(field, '');
    }
    storage.setItems({ [keyForStorage]: {} });
  });

  return (
    <section className="open-question-repeatable content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <div className="question">
              <h4 dangerouslySetInnerHTML={{ __html: question }}></h4>
              <div
                className="text-wrapper wysiwyg"
                dangerouslySetInnerHTML={{ __html: explanation }}
              />
              <form>
                <div className="form-group questionTextArea">
                  <textarea
                    className="form-control"
                    id="openQuestion"
                    rows="3"
                    {...register(`OpenQuestion_${id}`)}
                  />
                  {tooltip !== null && (
                    <div className="tooltipQuestion">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip id={`tooltip-left`}>{tooltip}.</Tooltip>}
                      >
                        <Button className="tooltipButton" variant="">
                          ?
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default OpenQuestion;
