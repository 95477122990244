import React, { useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip, Button, Table } from 'react-bootstrap';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';

export const RangeQuestion = observeStorage(
  ({ id, title, questions, explanation, tooltip, options }) => {
    const optionsArray = useMemo(() => options.split(';'), [options]);

    // get rid of the html tags
    const questionsArray = useMemo(() => questions.replace(/<\/?[^>]+(>|$)/g, '').split(';'), [
      questions,
    ]);

    const storage = useStorage();
    const keyForStorage = `range_question_${id}`;

    const [answers, setAnswers] = useState(storage.getItem(keyForStorage) ?? {});

    useOnResetChapter(() => {
      setAnswers({});
      storage.setItem(keyForStorage, {});
    });

    const onChangeHandler = (question, option) => () => {
      setAnswers((currentAnswers) => {
        const newAnswers = {
          ...currentAnswers,
          [question]: option,
        };
        storage.setItem(keyForStorage, newAnswers);
        return newAnswers;
      });
    };

    return (
      <section className="content-section text-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 wysiwyg">
              <div className="question">
                <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                <div className="d-flex justify-content-between">
                  <div
                    className="text-wrapper wysiwyg"
                    dangerouslySetInnerHTML={{ __html: explanation }}
                  />
                  {tooltip !== null && (
                    <div className="tooltipQuestion">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip id={`tooltip-left`}>{tooltip}.</Tooltip>}
                      >
                        <Button className="tooltipButton" variant="">
                          ?
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>

                <div className="table-responsive">
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th> </th>
                        {optionsArray.map((option, index) => (
                          <th key={index} className="text-center">
                            {option}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {questionsArray.map((question, index) => (
                        <tr key={index}>
                          <td>
                            <label htmlFor={index}>{question}</label>
                          </td>
                          {optionsArray.map((option, innerIndex) => {
                            return (
                              <td key={innerIndex} className="text-center align-middle">
                                <span className="radio-wrapper">
                                  <input
                                    className="radio"
                                    type="radio"
                                    id={`option-${index}-${innerIndex}`}
                                    name={`option-${index}-${innerIndex}`}
                                    checked={answers[question] === option}
                                    onChange={onChangeHandler(question, option)}
                                  />
                                  <label htmlFor={`option-${index}-${innerIndex}`} />
                                </span>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default RangeQuestion;
