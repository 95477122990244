import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from 'recharts';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';
import { groupBy } from 'lodash';

export const OnlineCognitionScale = observeStorage(({ id, content, questions }) => {
  const storage = useStorage();
  const keyForStorage = `internet_en_waarden_${id}`;
  const dataFromStorage = storage.getItem(keyForStorage) ?? {};

  const [showWarning, setShowWarning] = useState(false);
  const [answers, setAnswers] = useState(dataFromStorage.answers ?? {});

  const everyThingAnswered = Object.keys(answers).length === questions.length;
  const [showResults, setShowResults] = useState(
    everyThingAnswered && (dataFromStorage.showResults ?? false)
  );

  const resetHandler = () => {
    setAnswers({});
    setShowResults(false);
    storage.setItem(keyForStorage, {});
  };

  const saveShowResults = (value) => {
    setShowResults(value);
    storage.setItem(keyForStorage, { answers, showResults: value });
  };

  useOnResetChapter(resetHandler);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!everyThingAnswered) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      saveShowResults(true);
    }
  };

  const onChangeHandler = (key, value) => () => {
    setAnswers((currentAnswers) => {
      const newAnswers = {
        ...currentAnswers,
        [key]: value,
      };
      storage.setItem(keyForStorage, { answers: newAnswers, showResults });
      return newAnswers;
    });
  };

  const indicatorScores = useMemo(() => {
    let scores = [];
    const questionsPerTopic = groupBy(questions, 'topic');

    Object.keys(questionsPerTopic).forEach((topic) => {
      let answerTotal = 0;
      questionsPerTopic[topic].forEach((question) => {
        if (answers[question.id]) {
          answerTotal = answerTotal + parseInt(answers[question.id]);
        }
      });
      const grade = parseInt(((answerTotal - 34.45) / 12.09) * 10 + 50);
      scores.push({ label: topic, Cijfer: grade });
    });

    return scores;
  }, [answers, questions]);

  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 wysiwyg">
            <h2>Online Cognition Scale</h2>
            {!showResults ? (
              <form id="form">
                {questions.map((question, index) => {
                  return (
                    <div key={index} className="question">
                      <div className="justify-content-center">
                        <h4>
                          {index + 1}. {question.question}
                        </h4>
                        <div className="likert-scale">
                          <b>Zeer oneens</b>
                          <span className="separator" />
                          <b className="hide-on-mobile">Niet eens, niet oneens</b>
                          <span className="separator" />
                          <b>Zeer eens</b>
                        </div>
                        <div className="likert-scale-items">
                          {[...Array(7)].map((_, i) => (
                            <div
                              key={i}
                              className="radiobuttonWithLabelCognitionScale likertcircles"
                            >
                              <input
                                className="radio"
                                type="radio"
                                id={`q_${question.id}_${i}`}
                                name={`q_${question.id}`}
                                value={i + 1}
                                checked={answers[question.id] === i + 1}
                                onChange={onChangeHandler(question.id, i + 1)}
                              />
                              <label htmlFor={`q_${question.id}_${i}`} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {showWarning && (
                  <p className="warning-text">
                    U hebt niet alle antwoorden ingevuld. Vul alle antwoorden in om het formulier te
                    kunnen versturen.
                  </p>
                )}
                <div className="text-center">
                  <Button className="" variant="secondary" type="submit" onClick={onSubmit}>
                    Bekijk resultaat
                  </Button>
                </div>
              </form>
            ) : (
              <div id="resultCognitionScale">
                <div className="wysiwyg mb-5" dangerouslySetInnerHTML={{ __html: content }} />

                <ResponsiveContainer width="99%" height={500}>
                  <BarChart
                    data={indicatorScores}
                    layout="vertical"
                    margin={{
                      top: 5,
                      right: 30,
                      left: 40,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, 100]} />
                    <YAxis type="category" dataKey="label" height={30} />
                    <Tooltip />

                    <Text
                      style={{ fontSize: 2 }}
                      width={100}
                      textAnchor="middle"
                      scaleToFit={true}
                    />
                    <Bar dataKey="Cijfer">
                      {indicatorScores.map((entry, index) => (
                        <Cell
                          key={index}
                          fill={
                            entry.Cijfer >= 70
                              ? '#ae3831'
                              : entry.Cijfer >= 50
                              ? '#d36861'
                              : entry.Cijfer >= 30
                              ? '#45bac3'
                              : '#85d2d8'
                          }
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <div className="text-center">
                  <Button className="" variant="secondary" type="submit" onClick={resetHandler}>
                    Opnieuw
                  </Button>
                  <Link to="/zelfbewustzijn" className="btn btn-outline-secondary ml-2">
                    Naar overzicht
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export default OnlineCognitionScale;
