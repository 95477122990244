import React from 'react';
//import Chapter from './repeatables/chapter';
import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { observeStorage, useStorage } from '../templates/localStorageContext';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const Other = styled.div`
  background: var(--primary-light);
  color: var(--primary);
`;

const Between = styled.div`
  height: 2px;
  flex-grow: 1;
  max-width: 60px;
  background: var(--primary-light);
`;

const Current = styled.div`
  background: var(--primary);
  color: white;
`;

export const ChaptersProgress = observeStorage(
  ({ allChapters, currentChapter, nextModule, modulesPath }) => {
    const storage = useStorage();

    if (currentChapter == null || allChapters == null || allChapters.length === 0) {
      return <div />;
    }

    const currentIndex = allChapters.findIndex((node) => node.id === currentChapter.id);
    const first = currentIndex === 0;
    const last = currentIndex === allChapters.length - 1;

    let nextLink = !last ? allChapters[currentIndex + 1].fullpath : undefined;
    let prevLink = !first ? allChapters[currentIndex - 1].fullpath : undefined;

    return (
      <div className="chapter-progress-section">
        <div className="text-center d-print-none container chapter-progress-full">
          <div className="row align-items-center">
            <div className="col-2">
              {prevLink && (
                <Link className="btn btn-secondary" to={prevLink}>
                  Vorige
                </Link>
              )}
            </div>
            <div className="col-8 col-md-8 d-flex justify-content-center progress-wrapper">
              {allChapters.map((ch, idx) => {
                const isRead = storage?.isLoggedIn && storage?.isRead(ch.nimbuId);

                return (
                  <React.Fragment key={idx}>
                    <div className="d-flex align-items-center">
                      {idx === currentIndex ? (
                        <Current className="chapter-step">
                          {isRead ? (
                            <FontAwesomeIcon className="sideButtonIcon" icon={faCheck} />
                          ) : (
                            idx + 1
                          )}
                        </Current>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 0, hide: 10 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              {ch.title}
                            </Tooltip>
                          )}
                        >
                          <Other className="chapter-step">
                            <Link to={ch.fullpath}>
                              {isRead ? (
                                <FontAwesomeIcon className="sideButtonIcon" icon={faCheck} />
                              ) : (
                                idx + 1
                              )}
                            </Link>
                          </Other>
                        </OverlayTrigger>
                      )}
                    </div>
                    {idx < allChapters.length - 1 ? <Between key={idx + 1000} /> : ''}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="col-2">
              {nextLink && (
                <Link className="btn btn-secondary" to={nextLink}>
                  Volgende
                </Link>
              )}
              {!nextLink && nextModule && (
                <Link className="btn btn-tertiary" to={nextModule.fullpath}>
                  Volgende Module
                </Link>
              )}
            </div>
          </div>
          <div className="row align-items-center text-center back-link">
            <div className="col-12 mt-3">
              <Link to={modulesPath}>« Terug naar zelfhulpmodule</Link>
            </div>
          </div>
        </div>
        <div className="center d-print-none container d-none chapter-progress-mobile">
          <div className="row align-items-center progress-wrapper">
            <div className="col-3">
              {prevLink && (
                <Link className="chapterProgressbtnMobile btn btn-secondary" to={prevLink}>
                  {/* {obj.previous.title} */}
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Link>
              )}
            </div>
            <div className="col-6">
              {allChapters
                .filter((ch, idx) => idx === currentIndex)
                .map((ch, idx) => (
                  <ProgressBar key={idx} now={idx + 1} min={0} max={allChapters.length} />
                ))}
            </div>
            <div className="col-3">
              {nextLink && (
                <Link className="chapterProgressbtnMobile btn btn-secondary" to={nextLink}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              )}
              {!nextLink && nextModule && (
                <Link
                  className="chapterProgressbtnMobile btn btn-tertiary"
                  to={nextModule.fullpath}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ChaptersProgress;
