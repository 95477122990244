import React from 'react';
import Chapter from './chapter';

export const Chapters = ({ chapters }) => {
  //Show all
  return (
    <ul>
      {chapters.map((chapter, index) => (
        <Chapter
          key={index}
          order={index + 1}
          title={chapter.title}
          link={chapter.fullpath}
          id={chapter.nimbuId}
          short
        />
      ))}
    </ul>
  );
};

export default Chapters;
