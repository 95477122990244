/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import styled from 'styled-components';
import { dispatch } from 'use-bus';
import { observeStorage, useStorage } from '../../templates/localStorageContext';

const Section = styled.section`
  .not-logged-in {
    text-align: center;
  }
  .logged-in {
    text-align: center;
  }
  .next-chapter-button {
    margin-top: 2rem;

    svg {
      margin-left: 0.5rem;
    }
  }
`;

export const Progress = observeStorage(({ currentPage, content }) => {
  const storage = useStorage();
  const isLoggedIn = !!storage?.isLoggedIn;

  const query = useStaticQuery(graphql`
    query allModulesAndChaptersQuery {
      allChapters: allNimbuPage(
        sort: { fields: position, order: ASC }
        filter: { published: { eq: true }, template: { eq: "chapter.liquid" } }
      ) {
        edges {
          node {
            ...ModulePage
          }
        }
      }
    }
  `);

  const parentPath = currentPage.fullpath.split('/').slice(0, -2).join('/');
  const allChapters = useMemo(
    () =>
      query.allChapters.edges
        .filter((m) => m.node.fullpath?.startsWith(parentPath))
        .map((m) => m.node),
    [parentPath, query]
  );
  const startedChapterCount = useMemo(
    () => (isLoggedIn ? allChapters.filter((m) => storage.isRead(m.nimbuId)).length : 0),
    [allChapters, storage, isLoggedIn]
  );

  const nextChapter = useMemo(
    () => (isLoggedIn ? allChapters.find((m) => !storage.isRead(m.nimbuId)) : null),
    [allChapters, storage, isLoggedIn]
  );

  const progress = Math.ceil((startedChapterCount / allChapters.length) * 100);

  return (
    <Section className="module-progress-repeatable content-section text-section">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-5" dangerouslySetInnerHTML={{ __html: content }}></div>
          <div className="col-md-6 wysiwyg">
            {isLoggedIn ? (
              <div className="logged-in">
                {progress !== 100 ? (
                  <p>
                    Je bekeek {progress > 0 ? 'reeds ' : ''}
                    <strong>{`${progress}%`}</strong> van de onderdelen in deze module.
                  </p>
                ) : (
                  <p>
                    Je volgde <strong>alle onderdelen</strong> in deze zelfhulpmodule.
                  </p>
                )}
                <ProgressBar now={progress} label={`${progress}%`} />
                {progress !== 100 ? (
                  <div className="next-chapter">
                    <Button
                      className="next-chapter-button"
                      variant="tertiary"
                      onClick={() =>
                        nextChapter != null ? navigate(nextChapter.fullpath + '/') : null
                      }
                    >
                      Start met het {progress === 0 ? 'eerste' : 'volgende'} onderdeel{' '}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="not-logged-in">
                Je moet{' '}
                <a href="#" onClick={() => dispatch('showLogin')}>
                  aangemeld
                </a>{' '}
                zijn om je vooruitgang in deze modules te zien of bij te houden.
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
});

export default Progress;
