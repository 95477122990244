import React from 'react';

export default function Text({ content }) {
  return (
    <section className="content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-md-8 text-wrapper wysiwyg"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </section>
  );
}
