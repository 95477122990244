import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';
import classnames from 'classnames';
import { Link } from 'gatsby';

const Section = styled.section`
  .not-logged-in {
    text-align: center;
  }

  .logged-in {
    text-align: center;
  }

  .next-chapter-button {
    margin-top: 2rem;

    svg {
      margin-left: 0.5rem;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 5px;
  }

  h3 {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 24px;
    opacity: 0.8;
  }

  .checklist-item__name {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 2px;
  }

  .checklist-item__description {
    font-size: 14px;
    color: #666;
  }

  .checklist {
    margin-top: 3rem;
  }

  .checklist-item {
    .link-icon {
      transition: transform 0.2s cubic-bezier(0.25, 1, 0.5, 1);
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        .link-icon {
          transform: translateX(0.5rem);
        }
      }
    }
    .checklist-item__description {
      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

const ChecklistItem = ({ answers, id, onChangeHandler, name, description, link }) => {
  const hasDescription = useMemo(() => description && description.trim().length > 0, [description]);
  const hasLink = useMemo(() => link && link.trim().length > 0, [link]);
  const externalLink = useMemo(() => link && link.startsWith('http'), [link]);

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <div className={classnames('checklist-item', { checked: !!answers[id]?.checked })}>
          <input
            type="checkbox"
            id={`q_${id}`}
            name={`q_${id}`}
            value={'1'}
            checked={!!answers[id]?.checked}
            onChange={onChangeHandler(id)}
          />
          <label htmlFor={`q_${id}`}>
            {hasLink ? (
              <Link
                to={link}
                {...(externalLink ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
              >
                <div className="checklist-item__name">
                  {name} <FontAwesomeIcon className="link-icon" icon={faArrowRight} />
                </div>
                {hasDescription && <div className="checklist-item__description">{description}</div>}
              </Link>
            ) : (
              <>
                <div className="checklist-item__name">{name}</div>
                {hasDescription && (
                  <div
                    className="checklist-item__description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                )}
              </>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export const Checklist = observeStorage(({ id, title, subtitle, items }) => {
  const storage = useStorage();
  const keyForStorage = `checklist_${id}`;
  const dataFromStorage = storage.getItem(keyForStorage) ?? {};

  const [answers, setAnswers] = useState(dataFromStorage.answers ?? {});

  const resetHandler = () => {
    setAnswers({});
    storage.setItem(keyForStorage, {});
  };

  useOnResetChapter(resetHandler);

  const onChangeHandler = (itemId) => (e) => {
    const checked = !!e.target?.checked;
    setAnswers((currentAnswers) => {
      const newAnswers = {
        ...currentAnswers,
        [itemId]: {
          ...(currentAnswers[itemId] ?? {}),
          checked,
        },
      };
      storage.setItem(keyForStorage, { answers: newAnswers });

      return newAnswers;
    });
  };

  const hasTitle = useMemo(() => title && title.trim().length > 0, [title]);
  const hasSubtitle = useMemo(() => subtitle && subtitle.trim().length > 0, [subtitle]);

  return (
    <Section className="module-progress-repeatable content-section text-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 wysiwyg">
            {hasTitle && <h2>{title}</h2>}
            {hasSubtitle && <h3>{subtitle}</h3>}
            <div className="checklist">
              {items.map((item, index) => (
                <ChecklistItem
                  key={index}
                  index={index}
                  answers={answers}
                  {...item}
                  onChangeHandler={onChangeHandler}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
});

export default Checklist;
