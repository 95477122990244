import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import bgPattern from '../../images/header-bg-pattern.png';
import { observeStorage, useStorage } from '../../templates/localStorageContext';

const StyledTitle = styled.h1`
  &.has-subtitle {
    margin-bottom: 0;
  }
`;

const StyledSubtitle = styled.div`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;

  a {
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
  }
`;

export const Header = observeStorage(
  ({ title, subtitle, link, image, backgroundOverlay, backgroundOverlayOpacity, onlyForUsers }) => {
    const style = image
      ? { backgroundImage: `url('${getSrc(image)}')` }
      : { backgroundImage: `url('${bgPattern}')` };

    const storage = useStorage();
    const loggedIn = !!storage?.isLoggedIn;
    const hasSubtitle = useMemo(
      () => subtitle && subtitle.trim().length > 0 && (!onlyForUsers || (onlyForUsers && loggedIn)),
      [subtitle, onlyForUsers, loggedIn]
    );
    const hasLink = useMemo(() => link && link.trim().length > 0, [link]);
    const externalLink = useMemo(() => link && link.startsWith('http'), [link]);

    return (
      <section
        className={classnames(
          'heading-section overlay',
          `overlay-${backgroundOverlay}`,
          `overlay-opacity-${backgroundOverlayOpacity}`,
          { 'with-image': image }
        )}
        style={style}
      >
        {backgroundOverlay !== 'None' ? <div className="overlay"></div> : null}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="page-title-wrapper">
              {title ? (
                <StyledTitle className={classnames('title', { 'has-subtitle': hasSubtitle })}>
                  {title}
                </StyledTitle>
              ) : null}
              {hasSubtitle ? (
                <StyledSubtitle className="tagline">
                  {hasLink ? (
                    <Link
                      to={link}
                      {...(externalLink ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
                    >
                      {subtitle} <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  ) : (
                    subtitle
                  )}
                </StyledSubtitle>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default Header;
